const API = {
    V1: {
        accept: { 'Accept': 'application/vnd.api+json; version=1' },
        put: 'PUT',
        post: 'POST',
        delete: 'DELETE',
        methodBase: function(method) {
            return {
                method: method,
                headers: this.accept,
                contentType: 'application/json'
            }
        },
        typeAttr: function(type, attrs) {
            return {
               type: type,
               attributes: attrs
            }
        },
        buildIncludes: function(incl) {
            if (incl !== undefined) {
                var inclAttrs = [incl].flat();
                var included;

                if (inclAttrs.length > 0) {
                    included = [];

                    for (var i = 0; i < inclAttrs.length; i++) {
                        included.push(this.typeAttr(inclAttrs[i].type, inclAttrs[i].attrs));
                    }
                }

                return included
            }
        },
        buildRelationships: function(rel) {
            if (rel !== undefined) {
                var relationships = {};

                for (var key in rel) {
                    if (rel.hasOwnProperty(key)) {
                        if (Array.isArray(rel[key])) {
                            relationships[key] = {
                                data: rel[key].map(item => ({
                                    id: item.id,
                                    type: item.type
                                }))
                            };
                        } else {
                            relationships[key] = {
                                data: {
                                    id: rel[key].id,
                                    type: rel[key].type
                                }
                            };
                        }
                    }
                }

                return relationships;
            }
        },
        stringify: function(jsonData) {
            return JSON.stringify(jsonData, function (key, value) {
                                             // replace all keys with `_` to `-` for JSONAPI format
                                             if (value && Object.prototype.toString.call(value) === '[object Object]') {
                                               var replacement = {};
                                               for (var k in value) {
                                                 if (Object.hasOwnProperty.call(value, k)) {
                                                   replacement[k.replace(/_/g,'-')] = value[k];
                                                 }
                                               }
                                               return replacement;
                                             }
                                             return value;
                                           })
        },
        apiProcessData: function(method, type, attrs, incl, rel, meta) {
            var data = {
                type: type,
                attributes: attrs
            };
            if (rel) {
                data.relationships = rel;
            }
            var requestData = {
                data: data
            };
            if (incl) {
                requestData.included = incl;
            }
            if (meta) {
                requestData.meta = meta;
            }
            return $.extend(this.methodBase(method), {
                data: this.stringify(requestData)
            });
        },
        eoiTemplatesGet: function(attrs) {
            return $.extend(this.apiProcessData(this.get), {
                url: '/businesses/' + attrs.businessId + '/eoi_templates'
            })
        },
        eoiCreate: function(attrs, incl, rel, meta) {
            return $.extend(this.apiProcessData(this.post, 'eois', attrs, this.buildIncludes(incl), this.buildRelationships(rel), meta), {
                url: '/eois/'
            })
        },
        eoiDiscardAsBusinessUpdate: function(attrs, eoiId) {
            return $.extend(this.apiProcessData(this.put, 'eoi', attrs), {
                url: '/eois/' + eoiId + '/discard'
            })
        },
        eoiTemplateCreate: function(attrs, businessId) {
            return $.extend(this.apiProcessData(this.post, 'eoi_templates', attrs), {
                url: '/businesses/' + businessId + '/eoi_templates/'
            })
        },
        eoisGet: function(attrs) {
            return $.extend(this.apiProcessData(this.get), {
                url: '/eois?' + 'include=job,user,job.suburb,job.sub_categories' +
                     '&page[number]=' + attrs.page + '&page[size]=' + attrs.size +
                     '&sort=' + attrs.sort + '&filter[status]=' + attrs.status
            })
        },
        leadDelete: function(jobId) {
            return $.extend(this.apiProcessData(this.delete), {
                url: '/leads/' + jobId
            })
        },
        leadGet: function(attrs) {
            return $.extend(this.apiProcessData(this.get), {
                url: '/leads/' + attrs.id + '?include=attachments,user,suburb,sub_categories'
            })
        },
        leadViewedCreate: function(jobId) {
            return $.extend(this.apiProcessData(this.post), {
                url: '/leads/' + jobId + '/viewed/'
            })
        },
    },
    V2: {
        accept: { 'Accept': 'application/vnd.api+json; version=2' },
        post: 'POST',
        patch: 'PATCH',
        get: 'GET',
        methodBase: function(method) {
            return {
                method: method,
                headers: this.accept,
                contentType: 'application/json'
            }
        },
        typeAttr: function(type, attrs) {
            return {
               type: type,
               attributes: attrs
            }
        },
        buildIncludes: function(incl) {
            if (incl !== undefined) {
                var inclAttrs = [incl].flat();
                var included;

                if (inclAttrs.length > 0) {
                    included = [];

                    for (let i = 0; i < inclAttrs.length; i++) {
                        included.push(this.typeAttr(inclAttrs[i].type, inclAttrs[i].attrs));
                    }
                }

                return included
            }
        },
        stringify: function(jsonData) {
            return JSON.stringify(jsonData, function (key, value) {
                                             // replace all keys with `_` to `-` for JSONAPI format
                                             if (value && Object.prototype.toString.call(value) === '[object Object]') {
                                               var replacement = {};
                                               for (var k in value) {
                                                 if (Object.hasOwnProperty.call(value, k)) {
                                                   replacement[k.replace(/_/g,'-')] = value[k];
                                                 }
                                               }
                                               return replacement;
                                             }
                                             return value;
                                           })
        },
        apiProcessData: function(method, type, attrs, incl) {
            var json = $.extend({ data: this.typeAttr(type, attrs) },
                                { included: incl })
            return $.extend(this.methodBase(method), {
                data: this.stringify(json)
            })
        },
        userUpdate: function(attrs, userId) {
            return $.extend(this.apiProcessData(this.patch, 'users', attrs), {
                url: '/users/' + userId
            })
        },
        userGet: function(attrs) {
            return $.extend(this.apiProcessData(this.get, 'users', attrs), {
                url: '/users/' + attrs.id
            })
        },
        userCreate: function(attrs, incl) {
            return $.extend(this.apiProcessData(this.post, 'users', attrs, this.buildIncludes(incl)), {
                url: '/users/'
            })
        },
        userSession: function(attrs) {
            return $.extend(this.apiProcessData(this.post, 'user-sessions', attrs), {
                url: '/users/sign_in'
            })
        },
        businessCreate: function(attrs, incl) {
            return $.extend(this.apiProcessData(this.post, 'businesses', attrs, this.buildIncludes(incl)), {
                url: '/businesses'
            })
        },
        businessUpdate: function(attrs, businessId) {
            return $.extend(this.apiProcessData(this.patch, 'businesses', attrs), {
                url: '/businesses/' + businessId
            })
        },
        jobFilterSettings: function(attrs) {
            return $.extend(this.apiProcessData(this.get, 'businesses', attrs), {
                url: '/businesses/job_filter_settings',
                cache: false
            })
        },
        getJobsCount: function(attrs) {
            return $.extend(this.apiProcessData(this.get, 'businesses', attrs), {
                url: '/businesses/jobs_count'
            })
        },
        jobClosureData: function(attrs) {
            return $.extend(this.methodBase(this.get), {
                url: '/job/job_closure_data',
                async: false
            })
        },
        jobCreate: function(fullJsonData) {
            return $.extend(this.methodBase(this.post), {
                data: this.stringify(fullJsonData),
                url: '/jobs?include=credit_card'
            })
        },
        prePostJobCheck: function(attrs) {
          return $.extend(this.apiProcessData(this.get), {
            url: '/job/pre_post_job_check?sub_category_id=' + attrs['subCategoryId']+'&suburb_id=' + attrs['suburbId']+'&include=suburb_record'
          })
        },
        forgotPassword: function(attrs) {
            return $.extend(this.apiProcessData(this.post, 'forgot-password', attrs), {
                url: '/users/forgot_password'
            })

        },
        suburbGet: function(fullJsonData) {
            return $.extend(this.methodBase(this.get), {
                data: fullJsonData,
                url: '/suburbs'
            })
        },
        setupPhoneVerification: function(attrs) {
            return $.extend(this.apiProcessData(this.get, attrs), {
                url: '/validate/setup_phone_verification'
            })
        },
        skipPhoneVerification: function(attrs) {
            return $.extend(this.apiProcessData(this.post, attrs), {
                url: '/validate/skip_phone_verification'
            })
        },
        verifyEmail: function(email) {
            return $.extend(this.apiProcessData(this.get), {
                data: { email: email },
                url: '/api/users/check_email'
            })
        },
        verifyPhone: function(fullJsonData) {
            return $.extend(this.apiProcessData(this.post), {
                data: this.stringify(fullJsonData),
                url: '/validate/verify_phone'
            })
        },
        build: {
            attachment: function (opts) {
                return {
                    data: {
                        type: 'attachments',
                        relationships: {
                            job: {
                                data: {
                                    type: 'jobs',
                                    id: opts.jobId
                                }
                            }
                        }
                    }
                }
            }
        },
        attachment: function(opts) {
            return $.extend(this.methodBase(this.post), {
                url: '/attachments',
                processData: false,
                contentType: false,
                data: opts.data
            })
        },

        postAjax: function (url, data, cb) {
            $.ajax({
                url: window.App.url + '/' + url,
                data: data,
                method: 'POST',
                headers: { 'Accept': 'application/vnd.api+json; version=2' },
                processData: false,
                contentType: false
            })
            .always(cb)
            // TODO handle done and fail or perhpas promises???
        }
    },
    V3: {
        accept: { 'Accept': 'application/vnd.api+json; version=3' },
        get: 'GET',
        post: 'POST',
        patch: 'PATCH',
        delete: 'DELETE',
        methodBase: function(method) {
            return {
                method: method,
                headers: this.accept,
                contentType: 'application/json'
            }
        },
        typeAttr: function(type, attrs) {
            return {
               type: type,
               attributes: attrs
            }
        },
        stringify: function(jsonData) {
            return JSON.stringify(jsonData, function (key, value) {
                                             // replace all keys with `_` to `-` for JSONAPI format
                                             if (value && Object.prototype.toString.call(value) === '[object Object]') {
                                               var replacement = {};
                                               for (var k in value) {
                                                 if (Object.hasOwnProperty.call(value, k)) {
                                                   replacement[k.replace(/_/g,'-')] = value[k];
                                                 }
                                               }
                                               return replacement;
                                             }
                                             return value;
                                           })
        },
        apiProcessData: function(method, type, attrs, incl, rel, additionalData) {
            var data = {
                type: type,
                attributes: attrs
            };
            if (rel) {
                data.relationships = rel;
            }
            var requestData = {
                data: data
            };
            if (incl) {
                requestData.included = incl;
            }
            if (requestData.data && additionalData) {
                Object.assign(requestData.data, additionalData);
            }
            return $.extend(this.methodBase(method), {
                data: this.stringify(requestData)
            });
        },
        abnUniquenessGet: function(attrs) {
            return $.extend(this.methodBase(this.get), {
                url: '/id_check/abn/check_uniqueness?' + 'number=' + attrs.number
            })
        },
        addressCreate: function(attrs) {
            return $.extend(this.apiProcessData(this.post, 'address', attrs), {
                url: '/users/addresses/'
            })
        },
        addressGet: function(attrs) {
            return $.extend(this.methodBase(this.get), {
                url: '/users/addresses'
            })
        },
        businessGet: function(attrs) {
            return $.extend(this.methodBase(this.get), {
                url: '/users/business?' + 'include=capped_membership,job_filter,hubspot_sales_agent,membership,pricing,payment_type_preference,user'
            })
        },
        businessLogoDelete: function(attrs) {
            return $.extend(this.apiProcessData(this.delete), {
                url: '/users/business/destroy_logo'
            })
        },
        businessLogoUpdate: function(attrs) {
            return $.extend(this.methodBase(this.patch), {
                url: '/users/business',
                processData: false,
                contentType: false,
                cache: false,
                data: attrs
            })
        },
        businessPaymentTypePreferenceCreate: function(attrs) {
            return $.extend(this.apiProcessData(this.post, 'paymentType', attrs), {
                url: '/businesses/payment_type_preferences'
            })
        },
        businessPricingCreate: function(attrs) {
            return $.extend(this.apiProcessData(this.post, 'pricing', attrs), {
                url: '/businesses/pricing'
            })
        },
        businessPricingDelete: function() {
            return $.extend(this.apiProcessData(this.delete), {
                url: '/businesses/pricing'
            })
        },
        businessProfileCompletionCreate: function(attrs) {
            return $.extend(this.apiProcessData(this.post, 'profileCompletion', attrs), {
                url: '/businesses/profile_completions'
            })
        },
        businessUpdate: function(attrs) {
            return $.extend(this.apiProcessData(this.patch, 'business', attrs), {
                url: '/users/business/'
            })
        },
        discardedLeadsGet: function(attrs) {
            return $.extend(this.methodBase(this.get), {
                url: '/leads/discarded?' + 'include=sub_categories,suburb,user' + '&page[number]=' + attrs.page
            })
        },
        eoiUpdate: function(attrs, eoiId) {
            return $.extend(this.apiProcessData(this.patch, 'eoi', attrs), {
                url: '/eois/' + eoiId
            })
        },
        excludedMatchUpdate: function(attrs, additionalData) {
            return $.extend(this.apiProcessData(this.patch, 'excludedMatch', attrs, null, null, additionalData), {
                url: '/job_filters/excluded_match/'
            })
        },
        generateEoiIntroGet: function(attrs) {
            return $.extend(this.methodBase(this.get), {
                url: '/users/business/generate_eoi_intro' + '?job_id=' + attrs.jobId
            })
        },
        generateDescriptionGet: function(attrs) {
            return $.extend(this.methodBase(this.get), {
                url: '/users/business/generate_description'
            })
        },
        jobAttachmentsGet: function(attrs) {
            return $.extend(this.apiProcessData(this.get), {
                url: '/jobs/' + attrs['jobId'] + '/attachments'
            })
        },
        jobFilterGet: function(attrs) {
            return $.extend(this.methodBase(this.get), {
                url: '/businesses/job_filter'
            })
        },
        jobFilterUpdate: function(attrs) {
            return $.extend(this.apiProcessData(this.patch, 'jobFilter', attrs), {
                url: '/businesses/job_filter/'
            })
        },
        leadsAnalysisGet: function(attrs) {
            let url = '/leads/analysis?date_range=' + attrs.dateRange + '&statuses=' + attrs.statuses;

            if (attrs.startDate) {
                url += '&start_date=' + attrs.startDate;
            }

            if (attrs.endDate) {
                url += '&end_date=' + attrs.endDate;
            }

            return $.extend(this.methodBase(this.get), {
                url: url
            });
        },
        leadGet: function(attrs) {
            return $.extend(this.methodBase(this.get), {
                url: '/leads/' + attrs.leadId + '?include=sub_categories,suburb,user'
            })
        },
        leadsGet: function(attrs) {
            return $.extend(this.methodBase(this.get), {
                url: '/leads?' + 'include=sub_categories,suburb,user' + '&page[number]=' + attrs.page + '&sort=' + attrs.sort + '&pinned_lead_id=' + (attrs.pinnedLeadId || '')
            })
        },
        feedbackRequestCreate: function(attrs, eoiId) {
            return $.extend(this.apiProcessData(this.post, 'feedback', attrs), {
                url: '/feedback/feedback/' + eoiId
            })
        },
        fetchCappedMembershipDetailsGet: function(attrs) {
            return $.extend(this.methodBase(this.get), {
                url: '/users/business/fetch_capped_membership_details?' + 'subCategoryIds=' + attrs.subcategoryIds + '&radius=' + attrs.radius + '&suburbId=' + attrs.suburbId
            })
        },
        recalculateCappedMembershipGet: function(attrs) {
            return $.extend(this.methodBase(this.get), {
                url: '/users/business/recalculate_capped_membership_settings?' + 'excludedSubcategoryIds=' + attrs.excludedSubcategoryIds + '&excludedSuburbIds=' + attrs.excludedSuburbIds
            })
        },
        selfReportHireCreate: function(attrs, eoiId) {
            return $.extend(this.apiProcessData(this.post, 'eoi', attrs), {
                url: '/eois/' + eoiId + '/self_report'
            })
        },
        suburbFind: function(attrs) {
            return $.extend(this.methodBase(this.get), {
                url: '/suburbs/' + attrs.id
            })
        },
        businessAIAssistanceCreate: function(attrs) {
            return $.extend(this.apiProcessData(this.post, 'ai_assistance', attrs), {
                url: '/businesses/ai_assistance'
            })
        },
        usersEmailPreferencesUpdate: function(attrs) {
            return $.extend(this.apiProcessData(this.patch, 'emailPreferences', attrs), {
                url: '/users/update_email_preferences/'
            })
        }
    }
}

export default API
